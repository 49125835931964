import flagsmith from "flagsmith";
import { useCallback, useMemo } from "react";
import { useStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { applicationConfig } from "../application-configuration";
import { applicationSettingsStore, authClient } from "../store";

// I think we invision this to come from Flagsmith, but for now we will document these tenants here
export type MasterControlTenant = {
    // Not actually used; Just for documentation currently; It could be used to gate access if we get to that point
    environment: Set<"test" | "dev" | "stage" | "prod">;
    features: Set<"system-template-creation" | "mc-template-creation" | "mc-tag-creation" | "mc-list-creation">;
    id: string;
    // Not actually used; Just for documentation currently; It could be used to gate access if we get to that point
    region: Set<string>;
};

export const useShouldBeMocked = () => applicationConfig?.authentication?.shouldBeMocked === true;

/**
 * Returns a list of access group IDs for the authenticated user.
 * @returns A list of access group IDs, or an empty list of no access groups could be found.
 */
export const useAuthenticatedUserAccessGroups = () => {
    const [accessToken, accessGroups] = useStore(
        applicationSettingsStore,
        (s) => [s.accessToken, s.accessGroups] as const,
    );
    if (accessToken && accessGroups) {
        return accessGroups.get(accessToken) ?? [];
    }
    return [];
};

const doesGroupMatch = (requiredGroup: string | RegExp, group: string) =>
    requiredGroup instanceof RegExp ? requiredGroup.test(group) : requiredGroup.toLowerCase() === group.toLowerCase();

/**
 * Returns true if the user has access to one or more groups.
 * @param requiredGroup The group(s) to determine access for.
 * @returns True if the user has access, false if not. Defaults to false until access can be verified.
 */
export const useHasGroupAccess = (requiredGroup: string | RegExp | undefined | null | string[] | RegExp[]) => {
    const [accessToken, accessGroups] = useStoreWithEqualityFn(
        applicationSettingsStore,
        (s) => [s.accessToken, s.accessGroups],
        shallow,
    );
    if (!requiredGroup) {
        // No group provided means "you have access"
        return true;
    }

    if (accessGroups && accessToken) {
        const currentAccessGroups = accessGroups.get(accessToken);
        if (Array.isArray(requiredGroup)) {
            return Boolean(
                currentAccessGroups?.some((group) => requiredGroup.some((reqGroup) => doesGroupMatch(reqGroup, group))),
            );
        }

        return Boolean(currentAccessGroups?.some((group) => doesGroupMatch(requiredGroup, group)));
    }

    return false;
};

export const useNeedsAuthentication = () => {
    const shouldBeMocked = useShouldBeMocked();
    const isAuthenticated = useStore(applicationSettingsStore, (s) => s.isAuthenticated);
    const isLoginRedirect = authClient?.isLoginRedirect() ?? false;
    return useMemo(
        () => !shouldBeMocked && !isLoginRedirect && !isAuthenticated,
        [isAuthenticated, isLoginRedirect, shouldBeMocked],
    );
};

export const useAuthenticatedUserId = () => {
    return useStore(applicationSettingsStore, (s) => s.uid || undefined);
};

export const useAuthenticatedUsername = () => {
    return useStore(applicationSettingsStore, (s) => s.claims?.username || s.claims?.sub);
};

export const useGetClear = () => {
    const shouldBeMocked = useShouldBeMocked();
    return useCallback(() => {
        if (!shouldBeMocked) {
            if (authClient) {
                return authClient.tokenManager.clear();
            }
        }
    }, [shouldBeMocked]);
};

export const useSignOut = () => {
    const shouldBeMocked = useShouldBeMocked();
    return useCallback(async () => {
        if (!shouldBeMocked && authClient) {
            try {
                await flagsmith.logout();
            } catch (error) {
                console.error("FlagSmith log out error", error);
            }
            // Note: This will sign out of the entire Okta session, not just our application.
            await authClient.signOut({
                postLogoutRedirectUri: `${window.location.origin}/homepage`,
            });
        }
    }, [shouldBeMocked]);
};
